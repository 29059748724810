import { useQuery } from "@tanstack/react-query";
import { SmartPromptService } from "../services/smartprompt.service";
import { SmartPrompt } from "../types/smartprompt.type";

export const useFetchSmartPrompts = () => {
  return useQuery<SmartPrompt[], Error>({
    queryKey: ["smartprompts"],
    queryFn: () => SmartPromptService.getSmartPrompts(),
    staleTime: Infinity, // Never stale
    retry: 2, // Retry twice on failure
  });
};
