import { ChatService } from "../services/chat.service";
import { useLocalStorage } from "./use-local-storage";
import { useQuery } from "@tanstack/react-query";
import { Chat } from "../types/chat.types";

export const useChats = () => {
  return useQuery<Chat[], Error>({
    queryKey: ["chats"],
    queryFn: () => ChatService.getChatsByUser(),
    staleTime: 300000, // 5 minutes
    retry: 2, // Retry twice on failure
  });
};
