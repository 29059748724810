import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const PrivateRoute: React.FC = () => {
  const { user, isLoading, logout } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      setIsAuthenticated(!!user);
    }
  }, [user, isLoading]);

  useEffect(() => {
    if (isAuthenticated === false) {
      logout();
      navigate("/login"); // Redirect to login page or any other appropriate page
    }
  }, [isAuthenticated, logout, navigate]);

  if (isLoading || isAuthenticated === null) {
    return <h1 className="text-white">Loading...</h1>;
  }

  return <Outlet />;
};

export default PrivateRoute;
