import { useState, useRef, useEffect, forwardRef, useCallback } from "react";
import {
  PdfIcon,
  AttachIcon,
  StarInputIcon,
  SendIcon,
  RemoveIcon,
} from "../../assets";
import { Divider } from "@mui/material";
import { useChat } from "../../context/ChatContext";
import { Chat } from "../../types/chat.types";
import { OpenAIApiService } from "../../services/openai.service";
import { useLocalStorage } from "../../hooks/use-local-storage";
import { useGenerateDoc } from "../../context/DocContext";
import { documentService } from "../../services/document.service";
import { ChatService } from "../../services/chat.service";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useContext } from "react";
import { SmartPrompt } from "../../types/smartprompt.type";

interface InputProps {
  files: File[];
  onFilesChange: (files: File[]) => void;
  selectedCase: any;
  setSelectedCase: (usecase: {}) => void;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ files, onFilesChange, selectedCase, setSelectedCase }, ref) => {
    const { setHasStarted, hasStarted, setPrompt, showForm, setShowForm } =
      useGenerateDoc();
    // const [activeThread, setActiveThread] = useLocalStorage("activeThread", "");
    // const [activeAssistant, setActiveAssistant] = useLocalStorage(
    //   "activeAssistant",
    //   ""
    // );
    const [errorMessage, setErrorMessage] = useState("");
    const [showFilesPreview, setShowFilesPreview] = useState(true);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [userPrompt, setUserPrompt] = useState("");
    const {
      chats,
      setChats,
      activeChat,
      loadingResponse,
      setLoadingResponse,
      createChat,
      messages,
      setMessages,
    } = useChat();
    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const { setDocumentTitle, setDocument, setQuestionSections, setFormData } =
      appContext!!;

    const [isDragOver, setIsDragOver] = useState(false); // State to track if files are being dragged over

    const [selectedPrompt, setSelectedPrompt] = useLocalStorage(
      "selectedPrompt",
      ""
    );
    const [selectedUsecase, setSelectedUsecase] = useLocalStorage(
      "selectedCase",
      ""
    );

    // Listen for enter key
    useEffect(() => {
      const handleKeyDown = async (event: KeyboardEvent) => {
        if (event.key === "Enter") {
          await handleSendChat();
        }
      };
      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [userPrompt, activeChat]);

    const handleSendChat = async () => {
      if (userPrompt.trim() === "") return;

      if (activeChat && userPrompt) {
        if (files) {
          setShowFilesPreview(false);
        }

        setLoadingResponse(true);
        sendMessage(activeChat, userPrompt, selectedCase);
        setUserPrompt("");
        return;
      }

      if (!activeChat) {
        const chat = await createChat();
        console.log("chat created");

        if (chat) {
          sendMessage(chat.id, userPrompt);
          navigate(`/chat/${chat.id}`);
        }
      }
    };

    const sendMessage = async (
      chatId: string,
      userMessage: string,
      selectedCase?: SmartPrompt
    ) => {
      console.log("Selected Case", selectedCase);
      const checkGenerateDoc = async (prompt: string): Promise<boolean> => {
        try {
          return await documentService.checkGenerateDoc(prompt);
        } catch (error) {
          console.error("Error checking generateDoc:", error);
          return false;
        }
      };

      const checkDocGen = await checkGenerateDoc(userMessage);
      // setHasStarted(checkDocGen);
      setShowForm(checkDocGen);

      // if doc generation is true, set the document form data and navigate to the form
      if (checkDocGen) {
        const activeThread = JSON.parse(
          localStorage.getItem("activeThread") || ""
        );
        const activeAssistant = JSON.parse(
          localStorage.getItem("activeAssistant") || ""
        );
        console.log("sending generating form in input component..");
        console.log("activeThread", activeThread);
        console.log("activeAssistant", activeAssistant);
        console.log("userMessage", userMessage);
        let formData = await OpenAIApiService.generateForm(
          activeThread,
          activeAssistant,
          userMessage
        );

        formData = JSON.parse(formData.text);

        if (formData.document) setDocument(formData.document);
        if (formData.documentTitle) setDocumentTitle(formData.documentTitle);
        if (formData.questionSections) {
          setQuestionSections(formData.questionSections);
          setFormData([]);
          navigate("/documents/form");
        }
        setPrompt(userPrompt);
        setLoadingResponse(false);
        return;
      } else {
        setChats((prevChats) =>
          prevChats.map((chat) => {
            if (chat.id === chatId) {
              return {
                ...chat,
                messages: [
                  ...(chat.messages || []), // Ensure messages is an array
                  {
                    // id: crypto.randomUUID(),
                    role: "user",
                    content: userMessage,
                    uploadedFiles: files,
                    timestamp: new Date(),
                    appliedPromptId: selectedCase?.id,
                    appliedPromptTitle: selectedCase?.title,
                  },
                ],
                updatedAt: new Date(),
              } as Chat;
            }
            return chat;
          })
        );
        setMessages([
          ...messages,
          {
            id: crypto.randomUUID(),
            role: "user",
            content: userMessage,
            uploadedFiles: files,
            timestamp: new Date(),
          },
        ]);

        try {
          setLoadingResponse(true);

          const chatTitle = chats.find((chat) => chat.id === chatId)?.title;
          if (chatTitle === "New Chat") {
            const title = await generateTitle(userMessage);
            setChats((prevChats) =>
              prevChats.map((chat) => {
                if (chat.id === chatId) {
                  return {
                    ...chat,
                    title: title,
                  } as Chat;
                }
                return chat;
              })
            );
          }

          if (files && files.length > 0) {
            const vectorStoreId = chats.find(
              (chat) => chat.id === chatId
            )?.vectorStoreId;
            if (!vectorStoreId) {
              throw new Error("Vector store ID not found");
            }
            const uploaded = await uploadDoc(files, vectorStoreId);
            console.log("uploaded file", uploaded);
            onFilesChange([]);
            if (!uploaded) {
              throw new Error("Error uploading files");
            }
          }

          const threadId = JSON.parse(
            localStorage.getItem("activeThread") || ""
          );
          const assistantId = JSON.parse(
            localStorage.getItem("activeAssistant") || ""
          );

          // message = {
          //   userMessage: userMessage,
          //   smartPrompt: {
          //     id: selectedCase?.id,
          //     title: selectedCase?.title,
          //     instructions: selectedCase?.instruction,
          //   },
          // };

          const message = selectedCase?.id
            ? `${userMessage}\n\n[Prompt ID: ${
                selectedCase.id
              }]\n[Prompt Title: ${selectedCase.title}]\n[Prompt Instruction: ${
                selectedCase.instruction || "No instruction provided"
              }]`
            : userMessage;

          const { assistantResponse, threadMessage } =
            await OpenAIApiService.sendMessage(
              threadId,
              assistantId,
              JSON.stringify(message)
            );

          console.log("response received ", assistantResponse);

          if (files && files.length > 0) {
            // store doc metadata in db
            const docsMetadata = files.map((file) => {
              return {
                title: file.name,
                documentSize: file.size,
                filePath: "",
              };
            });
            await ChatService.addDocMetadata(
              chatId,
              threadMessage.id,
              docsMetadata
            );
          }

          const messages = chats.find((chat) => chat.id === chatId)?.messages;
          if (!messages) {
            throw new Error("Messages not found");
          }

          setChats((prevChats) =>
            prevChats.map((chat) => {
              if (chat.id === chatId) {
                return {
                  ...chat,
                  messages: [
                    ...chat.messages!,
                    {
                      id: crypto.randomUUID(),
                      role: "assistant",
                      content: assistantResponse.text,
                      timestamp: new Date(),
                    },
                  ],
                  updatedAt: new Date(),
                } as Chat;
              }
              return chat;
            })
          );
        } catch (error) {
          console.error("Error sending message:", error);
        } finally {
          setLoadingResponse(false);
        }
      }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newFiles = event.target.files ? Array.from(event.target.files) : [];
      onFilesChange([...files, ...newFiles]);
      setShowFilesPreview(true);
    };

    const generateTitle = async (text: string) => {
      try {
        const response = await OpenAIApiService.generateTitle(text);
        if (!response.title) {
          return "New Chat";
        }
        await ChatService.updateTitle(activeChat!, response.title);
        return response.title;
      } catch (error) {
        console.error("Error generating title:", error);
        return "";
      }
    };

    const uploadDoc = async (files: File[], vectorStoreId: string) => {
      try {
        const fileList = new DataTransfer();
        files.forEach((file) => fileList.items.add(file));
        const response = await OpenAIApiService.uploadFiles(
          vectorStoreId,
          fileList.files
        );

        if (response.status === "completed") {
          console.log("files uploaded to vector store");
          return true;
        }
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    };

    // Drag and Drop Handlers
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragOver(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragOver(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation(); // Prevent the event from reaching the global handler
      setIsDragOver(false);

      const droppedFiles = Array.from(event.dataTransfer.files);
      if (droppedFiles.length > 0) {
        const mergedFiles = [...files, ...droppedFiles];
        onFilesChange(mergedFiles);
      }
    };

    return (
      <div
        className={`bg-input rounded-[0.75rem] pl-[0.75rem] box-border relative  w-full${
          isDragOver ? "ring-2 ring-blue-500" : ""
        }`}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{ transition: "box-shadow 0.3s ease-in-out" }}
      >
        {/* File Preview Section */}
        {showFilesPreview && files && files.length > 0 && (
          <>
            <div className="overflow-x-auto custom-scrollbar pt-[0.5rem]">
              <div className="flex w-fit gap-2">
                {files.map((file, index) => (
                  <div
                    key={index}
                    className="flex relative bg-gray-700  items-center justify-between  rounded-[0.75rem] w-[15rem] overflow-hidden px-[1rem] py-[0.625rem]"
                  >
                    <div className="flex items-center gap-[0.5rem]">
                      <PdfIcon className="w-[1.75rem] h-[1.75rem]" />
                      <div className="flex flex-col text-start p-0 m-0 w-[50%] overflow-hidden ">
                        <p className="text-sm font-medium truncate p-0 m-0 tracking-[-0.0175rem]">
                          {file.name}
                        </p>
                        <p className="text-xs text-text-color p-0 m-0">
                          {(file.size / 1024).toFixed(2)} KB
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={() =>
                        onFilesChange(files.filter((_, i) => i !== index))
                      }
                      className="bg-gray-200 absolute w-[1rem] h-[1rem] top-[0.25rem] right-[0.25rem] flex items-center justify-center border-2 border-white text-white text-xs rounded-full"
                    >
                      x
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <Divider
              sx={{
                backgroundColor: "var(--gray-700)",
                marginX: "0.5rem",
                marginTop: "0.5rem",
              }}
            />
          </>
        )}

        {selectedCase.id && (
          <div className="flex items-center gap-2 mt-2">
            <h2 className="text-text-color m-0 p-0 tracking-[-0.035rem] text-sm">
              Smart Prompt:
            </h2>
            <p>
              <div
                key={selectedCase.id}
                className="relative w-fit text-nowrap flex items-center bg-primary-inside py-[0.375rem] px-[1rem] border border-stroked rounded-[6.25rem]"
                style={{
                  border: "1px solid #6381FC",
                }}
              >
                <button className="absolute top-[-0.3rem] right-0  bg-transparent border-none text-white cursor-pointer">
                  <RemoveIcon
                    onClick={() => {
                      setSelectedUsecase(" ");
                      setSelectedPrompt(" ");
                      setSelectedCase({});
                    }}
                  />
                </button>
                <label
                  htmlFor={selectedCase.id}
                  className="text-sm font-normal "
                >
                  {selectedCase.title}
                </label>
              </div>
            </p>
          </div>
        )}

        <div className="flex justify-between items-center pl-3 w-full rounded-[0.75rem] h-[3.25rem]">
          <div className="flex flex-row justify-center items-center gap-[0.5rem]">
            <div
              className="h-full flex items-center hover:cursor-pointer"
              onClick={() => fileInputRef.current?.click()}
            >
              <input
                className="appearance-none hidden"
                type="file"
                ref={fileInputRef}
                multiple={true}
                accept=".pdf"
                onChange={handleFileChange}
              />
              <AttachIcon className="w-[1.5rem] h-[1.5rem]" />
            </div>
            <hr className="h-[1.5rem]  w-[0.1rem] bg-darkslategray" />
            <StarInputIcon className="w-[1.5rem] h-[1.5rem]" />
          </div>
          <input
            ref={ref}
            type="text"
            className="w-[90%] pl-2 text-white bg-input outline-none"
            placeholder="Ask AI Frank.."
            value={userPrompt}
            onChange={(e) => setUserPrompt(e.target.value)}
            disabled={hasStarted}
          />
          <div className="bg-stroked flex items-center justify-center  p-[0.75rem] mr-6 rounded-[0.75rem]">
            <SendIcon
              className="w-[1.04938rem] h-[1.04938rem] cursor-pointer"
              onClick={handleSendChat}
            />
          </div>
        </div>
      </div>
    );
  }
);
