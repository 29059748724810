import React, { useEffect } from "react";
import { bouncy } from "ldrs";

// Ensure the custom element is registered once
bouncy.register();

interface LoaderProps {
  size?: string;
  speed?: string;
  color?: string;
}

export const Loader: React.FC<LoaderProps> = ({
  size = "45",
  speed = "1.75",
  color = "white",
}) => {
  // `l-bouncy` is a custom element provided by ldrs.
  // Since it's a web component, you can use it directly as JSX after registering.

  return <l-bouncy size={size} speed={speed} color={color}></l-bouncy>;
};
