import React from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../footer/footer';
import { ImgLogo } from '../../assets'; // Ensure ImgLogo is an imported component or image path

const ErrorPage = () => {
  return (
    <div className="h-[calc(100vh-20px)] flex flex-col items-center justify-center bg-background text-white  overflow-hidden relative">
  
      {/* Main content container */}
      <div className="text-center  space-y-12 max-w-3xl my-auto mx-auto px-4">
        {/* Logo */}
        <div className="mb-10">
          <ImgLogo className="w-[15rem] h-auto mx-auto " />
        </div>

        {/* Error Heading */}
        <h1 className="text-6xl font-extrabold bg-clip-text text-text-color mb-6 ">
          Oops! Something went wrong.
        </h1>

     

        {/* Back to Home Button */}
        <div className="flex flex-col w-full my-[1.5rem]">

        <Link
          to="/"
          className="flex justify-center items-center gap-[0.25rem] border rounded-[6.25rem] text-white font-poppins border-[#6381FC] h-[3rem] pt-[0.5rem] pr-[1.25rem] pb-[0.5rem] pl-[0.875rem] bg-[#292749] hover:cursor-pointer"
          >
          Go Back to Home
        </Link>
            </div>

      </div>
      {/* <div className='fixed bottom-0 overflow-hidden'>

      <Footer/>
      </div> */}
    </div>
  );
};

export default ErrorPage;
