import React, { useState, useEffect, useRef } from "react";
import { useChat } from "../../context/ChatContext";
import { Message } from "../../types/chat.types";
import { AIBotIcon, PdfIcon } from "../../assets";
import Markdown from "react-markdown";
import { useGenerateDoc } from "../../context/DocContext";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export const Chat = () => {
  const { chats, activeChat, loadingResponse, loadingMessages } = useChat();
  const [messages, setMessages] = useState<Message[]>([]);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const { showForm } = useGenerateDoc();

  useEffect(() => {
    // Get the active chat messages
    const activeChatMessages =
      chats.find((chat) => chat.id === activeChat)?.messages || [];
    setMessages(activeChatMessages);
    console.log("activeChatMessages", activeChatMessages);

    // Scroll to the bottom of the chat container
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chats, activeChat]);

  useEffect(() => {
    // Scroll to the bottom of the chat container whenever messages change
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          chatContainerRef.current;
        if (scrollTop + clientHeight < scrollHeight - 100) {
          setShowScrollButton(true);
        } else {
          setShowScrollButton(false);
        }
      }
    };

    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      data-test="chat-container"
      className="w-full p-4 overflow-y-auto h-[calc(100vh-24rem)] overflow-x-hidden z-0 "
      ref={chatContainerRef}
    >
      <div data-test="chat-messages" className="flex w-full flex-col gap-4  ">
        {messages.map((message) => (
          <div
            key={message.id}
            data-test={`message-${message.role}`}
            className={`flex ${
              message.role === "user" ? "justify-end" : "justify-start"
            }`}
          >
            <div className="flex w-full flex-col gap-y-2">
              <div className="flex items-end justify-end w-fit self-end mr-8 gap-3 max-w-xl flex-wrap">
                {message.uploadedFiles?.map((file, index) => (
                  <div
                    key={index}
                    className="flex relative items-center justify-between bg-gray-700 rounded-[0.75rem] overflow-hidden px-6 py-[0.625rem]"
                  >
                    <div className="flex items-center gap-[0.5rem]">
                      <PdfIcon className="w-[1.75rem] h-[1.75rem]" />
                      <div className="flex flex-col text-start p-0 m-0 overflow-hidden ">
                        <p className="text-sm font-medium p-0 m-0 tracking-[-0.0175rem]">
                          {file.name}
                        </p>
                        <p className="text-xs text-text-color p-0 m-0">
                          {(file.size / 1024).toFixed(2)} KB
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div
                data-test="chat-container"
                className={`px-[1rem] py-[0.625rem] flex  gap-3 text-start rounded-lg max-w-[80%] md:max-w-[60%] ${
                  message.role === "user" ? "bg-gray-200 self-end mr-8" : ""
                }`}
              >
                {message.role !== "user" && (
                  <div>
                    <AIBotIcon className="w-[1.625rem] h-[1.825rem]" />
                  </div>
                )}

                <p className="m-0 p-0 text-base text-text-color font-poppins font-normal leading-[1.75rem] tracking-[-0.02rem]">
                  {message.role !== "user" ? (
                    <Markdown className="-mt-4">{message.content}</Markdown>
                  ) : (
                    message.content
                  )}
                </p>
              </div>
              {
                // show smart prompt if applied
                message.appliedPromptId && (
                  <p className="flex  items-end justify-end m-0 p-0 mr-8">
                    <div
                      key={message.appliedPromptId}
                      className="relative w-fit text-nowrap flex items-center bg-primary-inside py-[0.375rem] px-[1rem] border border-stroked rounded-[6.25rem]"
                      style={{
                        border: "1px solid #6381FC",
                      }}
                    >
                      <label
                        htmlFor={message.appliedPromptId}
                        className="text-sm font-normal "
                      >
                        {message.appliedPromptTitle}
                      </label>
                    </div>
                  </p>
                )
              }
            </div>
          </div>
        ))}

        {loadingResponse && (
          <div className="flex justify-start">
            <div className="px-[1rem] py-[0.625rem] flex gap-3 text-start rounded-lg max-w-[60%] ">
              <div>
                <AIBotIcon className="w-[1.625rem] h-[1.825rem]" />
              </div>
              <div className="flex items-center gap-1">
                <div className="rounded-full w-[0.4rem] h-[0.4rem] animate-dot1 bg-gray-500"></div>
                <div className="rounded-full w-[0.4rem] h-[0.4rem] animate-dot2 bg-gray-500"></div>
                <div className="rounded-full w-[0.4rem] h-[0.4rem] animate-dot3 bg-gray-500"></div>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      {showScrollButton && (
        <div className="flex justify-center w-full absolute bottom-40">
          <button
            onClick={scrollToBottom}
            className="bg-primary-inside border transition-all border-stroked text-white p-2 fixed rounded-full shadow-lg"
          >
            <ArrowDownwardIcon />
          </button>
        </div>
      )}
    </div>
  );
};
