import { useQuery } from '@tanstack/react-query';
import { documentService } from '../services/document.service';
import { Document } from '../types/document.types';

export const useDocuments = () => {
  return useQuery<Document[], Error>({
    queryKey: ['documents'],
    queryFn: () => documentService.getAllDocuments(),
    staleTime: 300000, // 5 minutes
    retry: 2, // Retry twice on failure
  });
};
