import { ApiService } from "./api.service";
import { ApiEndpoint } from "./api.service";
import {
  SignupData,
  LoginData,
  ErrorLoginResponse,
  SuccessLoginResponse,
  SuccessSignupResponse,
  ErrorSignupResponse,
} from "../types/auth.types";
import { User } from "../types/user.type";

// Define the response types for the login endpoint
type LoginResponse = ErrorLoginResponse | SuccessLoginResponse;

type SignupResponse = ErrorSignupResponse | SuccessSignupResponse;

type GetUserResponse = User | any;

class AuthService {
  // Get User
  public async getCurrentUser(): Promise<GetUserResponse> {
    try {
      const response = await ApiService.get<null, GetUserResponse>(
        ApiEndpoint.GetUser,
        null
      );
      if (response) {
        const user = {
          id: response.id,
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
        };

        return user;
      }
    } catch (error) {
      console.error("Failed to fetch current user:");
      throw new Error("Unable to fetch user details.");
    }
  }
  // Register User
  // public async register<T>(userData: SignupData): Promise<T> {
  //   return ApiService.post<typeof userData, T>(ApiEndpoint.Register, userData);
  // }
  public async register<T>(userData: SignupData): Promise<SignupResponse> {
    let response;
    try {
      response = await ApiService.post<typeof userData, SignupResponse>(
        ApiEndpoint.Register,
        userData
      );

      if (response.status === 201 && "user" in response) {
        localStorage.setItem("accessToken", response.accessToken);

        return response;
      }

      if ("message" in response) {
        console.error("Signup:", response.message);
      } else {
        console.error("Signup failed with unknown error");
      }

      return response; // Return the error response
    } catch (error: any) {
      const status = error.response.status; // 403
      const message = error.response.data?.message || "No message provided"; // 'Invalid credentials'

      console.log(`Error: ${message}`);

      return { status: status, message: message };
    }
  }

  // Login User
  public async login(credentials: LoginData): Promise<LoginResponse> {
    let response;
    try {
      response = await ApiService.post<typeof credentials, LoginResponse>(
        ApiEndpoint.Login,
        credentials
      );

      if (response.status === 200 && "user" in response) {
        localStorage.setItem("accessToken", response.accessToken);
        return response;
      }

      if ("message" in response) {
        console.error("Login failed:", response.message);
      } else {
        console.error("Login failed with unknown error");
      }

      return response; // Return the error response
    } catch (error: any) {
      const status = error.response.status; // 403
      const message = error.response.data?.message || "No message provided"; // 'Invalid credentials'

      console.log(`Error: ${message}`);

      return { status: status, message: message };
    }
  }

  // Forgot Password
  public async forgotPassword(
    email: string
  ): Promise<{ status: string; resetId: string }> {
    return ApiService.post<
      { email: string },
      { status: string; resetId: string }
    >(ApiEndpoint.ForgotPassword, { email });
  }

  // Reset Password
  public async resetPassword(data: {
    id: string;
    code: string;
    newPassword: string;
  }): Promise<{ status: string }> {
    return ApiService.post<typeof data, { status: string }>(
      ApiEndpoint.ResetPassword,
      data
    );
  }

  // Helper function to get the token from cookies
  private getTokenFromCookies(): string | undefined {
    if (typeof document !== "undefined") {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        cookie = cookie.trim();
        if (cookie.startsWith("access_token=")) {
          return cookie.substring("access_token=".length, cookie.length);
        }
      }
    }
    return undefined;
  }

  // Helper function to set the token in cookies
  private setTokenToCookies(token: string): void {
    document.cookie = `access_token=${token}; path=/; secure; httpOnly; SameSite=Strict; max-age=3600`;
  }

  public logout(): void {
    // ApiService.get(ApiEndpoint.Logout, null);
    ApiService.logout();
  }
}

// Initialize AuthService instance
export const authService = new AuthService();
