// this function is responsible for generating sections for the form
interface GenerateDocumentParams {
  tempDocument: string;
  tempCity: string;
  setError: (error: string) => void;
  setDocument: (document: string) => void;
  setCity: (city: string) => void;
  setLoading: (loading: boolean) => void;
  setGeneratedDocument: (document: string) => void;
  setDocumentTitle: (title: string) => void;
  setQuestionSections: (sections: any) => void;
  setFormData: (data: any) => void;
  navigate: (path: string) => void;
}

export const generateDocument = async ({
  tempDocument,
  tempCity,
  setError,
  setDocument,
  setCity,
  setLoading,
  setGeneratedDocument,
  setDocumentTitle,
  setQuestionSections,
  setFormData,
  navigate,
}: GenerateDocumentParams) => {
  setError("");

  if (tempDocument.trim() === "" || tempCity.trim() === "") {
    setError("Document and city are required");
    return;
  }
  setDocument(tempDocument);
  setCity(tempCity);
  const openAIKey = process.env.REACT_APP_OPENAI_API_KEY;
  if (!openAIKey) {
    console.error("OpenAI API key is not set");
    return;
  }

  setLoading(true);

  try {
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${openAIKey}`,
      },
      body: JSON.stringify({
        model: "gpt-4o-mini",
        messages: [
          { role: "system", content: "You are a helpful assistant." },
          {
            role: "user",
            content: `Generate a legal document for ${tempDocument} in ${tempCity}. Return response as JSON format with keys "document" , "questionSections" and "documentTitle"."document" should contain markdown formatted content for the document. "questionSections" should be an array of json objects like ["sectionTitle":"sectionTitle", [{"question": "question", "type": "type", "placeholder":"placeholder"}]]. Type can be "textinput", "checkbox" or "textarea". maximum "questions" are 5 and each "section" in it could contain maximum 5 question. "documentTitle" should be "text"`,
          },
        ],
        max_tokens: 1400,
      }),
    });

    const data = await response.json();

    if (
      data.choices &&
      data.choices[0].message &&
      data.choices[0].message.content
    ) {
      try {
        // Clean and parse the JSON response
        const rawContent = data.choices[0].message.content
          .replace(/```json|```|```markdown/g, "")
          .replace(/^[^{]*/, "")
          .trim();

        const parsedResponse = JSON.parse(rawContent);

        if (parsedResponse.document)
          setGeneratedDocument(parsedResponse.document);
        if (parsedResponse.documentTitle)
          setDocumentTitle(JSON.stringify(parsedResponse.documentTitle));
        if (parsedResponse.questionSections) {
          setQuestionSections(parsedResponse.questionSections);
          setFormData([]);
          navigate("/documents/form");
        }
      } catch (error) {
        console.error("Error parsing JSON response:", error);
        setError("An error occurred while processing the document.");
      }
    } else {
      setError(
        "An error occurred while generating the document. Please try again."
      );
    }
  } catch (error) {
    console.error("Error generating document:", error);
    setError(
      "An error occurred while generating the document. Please try again."
    );
  }

  setLoading(false);
};
