import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  content: string;
  onClose: (confirmed: boolean) => void;
}

export function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { onClose, title, content, open } = props;

  const handleClose = (confirmed: boolean) => {
    onClose(confirmed);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "8px",
          padding: "16px",
          backgroundColor: "#292749",
          color: "white",
          border: "1px solid #6381fc",
        },
      }}
    >
      <DialogTitle
        id="confirmation-dialog-title"
        sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="confirmation-dialog-description"
          sx={{ fontSize: "1rem", color: "white" }}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(false)}
          sx={{
            color: "#fff",
            backgroundColor: "#FF8663",
            "&:hover": {
              backgroundColor: "#a8553d",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleClose(true)}
          sx={{
            color: "#fff",
            backgroundColor: "#1976d2",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
